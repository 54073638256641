.slider-container {
	margin: 0 auto;
	margin-top: 100px;
	position: relative;
	width: 1200px;
	height: 600px;
	border: 2px solid rgb(135, 206, 250);
}
.background-img {
	background-image: url('../images/planwall1.jpg');
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: 1200px 100%;
	opacity: 1;
}
.foreground-img {
	background-image: url('../images/planwallcolor2.jpg');
	position: absolute;
	left: 0;
	width: 100%;
	height: 100%;
	background-size: 1200px 100%;
	opacity: 1;
}
.slider {
	position: absolute;
	-webkit-appearance: none; /* Override default CSS styles */
	appearance: none;
	width: 100%; /* Full-width */
	height: 100%; /* Specified height */
	outline: none; /* Remove outline */
	background-color: skyblue;
	opacity: 0.2; /* Set transparency (for mouse-over effects on hover) */
	-webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
}
/* Mouse-over effects */

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
	-webkit-appearance: none; /* Override default look */
	appearance: none;
	width: 10px; /* Set a specific slider handle width */
	height: 600px; /* Slider handle height */
	background: black; /* Green background */
	cursor: pointer; /* Cursor on hover */
	box-shadow: 2px 2px 3px darkgray;
	border: 1px solid black;
}

.slider::-moz-range-thumb {
	width: 25px; /* Set a specific slider handle width */
	height: 25px; /* Slider handle height */
	background: #4caf50; /* Green background */
	cursor: pointer; /* Cursor on hover */
}
.slider-button {
	pointer-events: none;
	position: relative;
	width: 36px;
	height: 36px;
	border: 0.5px solid lightskyblue;
	border-radius: 50%;
	background-color: white;
	top: calc(50% - 18px);
	box-shadow: 2px 2px 3px darkgray;
}
.calculate-message {
	margin: 0 auto;
	width: 400px;
	height: 100px;
}
.message,
.show-message,
.survey-message,
.intro-message {
	width: 400px;
	color: lightskyblue;
	margin: 0 auto;
	margin-top: 20px;
	text-align: center;
}
.message {
	display: flex;
	justify-content: space-between;
}
.intro-message,
.caption-message {
	color: white;
	width: 600px;
	padding-top: 15px;
	padding-bottom: 5px;
}
.caption-message {
	margin: 0 auto;
	text-align: center;
	font-size: 30px;
}

/* Responsive */
@media (max-width: 1024px) {
	.slider-container {
		width: 1000px;
		height: 500px;
	}
	.background-img {
		background-size: 1000px 100%;
	}
	.foreground-img {
		background-size: 1000px 100%;
	}
	.slider::-webkit-slider-thumb {
		height: 500px; /* Slider handle height */
	}
}
@media (max-width: 768px) {
	.slider-container {
		width: 700px;
		height: 350px;
	}
	.background-img {
		background-size: 700px 100%;
	}
	.foreground-img {
		background-size: 700px 100%;
	}
	.slider::-webkit-slider-thumb {
		height: 350px; /* Slider handle height */
	}
	.slider-button {
		display: none;
	}
}
@media (max-width: 414px) {
	.slider-container {
		width: 400px;
		height: 220px;
	}
	.background-img {
		background-size: 400px 100%;
	}
	.foreground-img {
		background-size: 400px 100%;
	}
	.slider::-webkit-slider-thumb {
		height: 220px; /* Slider handle height */
	}
	.calculate-message,
	.show-message,
	.message,
	.survey-message {
		width: 310px;
		font-size: 12px;
	}
.intro-message, .caption-message{ color:white;
width: 300px;
padding-top: 15px;
padding-bottom: 5px;
}
.caption-message{
	font-size: 20px;
}
.intro-message{
	font-size: 13px;
}

}

@media (max-width: 375px) {
	.slider-container {
		width: 320px;
		height: 180px;
	}
	.background-img {
		background-size: 320px 100%;
	}
	.foreground-img {
		background-size: 320px 100%;
	}
	.slider::-webkit-slider-thumb {
		height: 180px; /* Slider handle height */
	}
}
