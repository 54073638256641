/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .main-board {
  width: 80%;
  display: block;
  margin: 0 auto;
} */

body {
	margin: 0;
	padding: 0;
	font-family: 'Lato';
	background-color: rgb(39, 39, 39);
}
nav {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid black;
	box-shadow: 0.5px 0.5px 7px black;
	height: 100px;
	width: 100%;
}
ul {
	list-style-type: disc;
	margin-block-start: 0em;
	margin-block-end: 0em;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-inline-start: 0px;
}
p {
}
a {
	text-decoration: none;
}

.main-logo {
	width: 85px;
	height: auto;
	margin: 0px 20px;
	opacity: 0.8;
}
.main-logo:hover {
	opacity: 1;
}
.top-nav {
	list-style: none;
	margin-right: 20px;
}
.main-background {
	background: linear-gradient(rgba(135, 206, 250, 0.6), rgba(0, 0, 0, 0.7)),
		url('./images/construcion_main.jpg');
	background-position: center;
	width: 100%;
	height: 520px;
	background-size: cover;
	position: relative;
	margin: 0;
	box-shadow: 1px 1px 3px black;
}
.over-lay {
	background-color: rgba(39, 39, 39, 0.8);
	box-shadow: 2px 2px 8px black !important;
}
.top-nav-list {
	display: inline;
	line-height: 1.6;
	padding: 0px 20px;
	font-size: 18px;
	color: lightskyblue;
}
.top-nav-list:hover {
	color: lightskyblue !important;
	opacity: 0.7;
}
.link {
	border-bottom: 1px solid transparent;
	display: inline;
}
.link:hover {
	border-bottom: 1px solid lightskyblue;
}
.caption-message-div {
	position: relative;
	width: 800px;
	margin: 0 auto;
	margin-top: -280px;
	z-index: 10;
}
.intro-message-div{
	width: 620px;
	opacity: 0.7;
	margin:0 auto;
	margin-top: 30px;
	background-color: black;
	border-radius: 15px;
}
h4 {
	font-family: 'Quicksand';
	font-size: 16px;
	margin: 5px 0px 5px 0px;
}
.breakdown-title {
	font-size: 18px;
	margin-bottom: 15px;
	font-weight: 600;
	text-align: center;
}
.breakdown-header {
	margin-bottom: 5px;
	color: lightskyblue;
	font-weight: 600;
	display: flex;
	justify-content: space-between;
}
.highlight-cost {
	color: lightskyblue;
	/* border-top:0.5px solid rgba(105,206, 250, 0.4); */
	border-bottom: 0.5px dotted rgba(105, 206, 250, 0.4);
	padding: 2px 0px;
	padding-bottom: 5px;
	margin-bottom: 15px;
}

/* .container {
	background-color: rgb(39, 39, 39);
} */
.main-board {
	width: 80%;
	margin: 0px auto;
	margin-bottom: 20px;
	border-radius: 15px;
	justify-content: space-between;
}
.main-board2 {
	margin: 0 10%;
}
.customer-board,
.report-board,
.inputs-board {
	display: flex;
	justify-content: space-around;
}
/* .project-info,.project-input {
  width: 300px;
} */
.wrapper {
	display: flex;
	justify-content: space-evenly;
	margin: 0 auto;
}
fieldset {
	box-shadow: 4px 4px 8px black;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
	padding-block-start: 0;
	padding-inline-start: 2rem;
	padding-inline-end: 2rem;
	padding-block-end: 2rem;
}
.field-set {
	border: 1px solid lightskyblue;
	margin-bottom: 20px;
	border-radius: 15px;
}
.legend {
	padding: 0px 10px 0px 10px;
}
.legend-text {
	font-size: 18px;
	font-weight: bold;
	background-color: rgb(39, 39, 39);
	text-shadow: 2px 2px 3px black;
	color: lightskyblue;
}
.project-info,
.project-input,
.project-report {
	color: rgba(238, 240, 242, 0.7);
	margin: 0px 20px;
	margin-bottom: 20px;
}
.project-input {
	margin: 0 10px;
}
.project-report {
	margin: 0 10px;
	min-width: 400px;
}
.project-info-input1,
.project-info-input2,
.project-info-input3 {
	border: 1px solid rgba(105, 206, 250, 0.4);
	margin-top: 3px;
	margin-bottom: 10px;
	height: 30px;
	width: 260px;
	padding-left: 5px;
	border-radius: 5px;
	background-color: rgba(252, 252, 252, 0.03);
	color: lightskyblue;
	box-shadow: 2px 2px 4px black;
}
.project-info-input3 {
	width: 269px;
	height: 35px;
}
:hover.project-info-input1,
:hover.project-info-input2,
:hover.project-info-input3 {
	background-color: rgba(252, 252, 252, 0.7);
	color: black;
}

.radio-button {
	width: 15px;
	height: 15px;
	margin-left: 10px;
	margin-right: 55px;
	width: 15px;
	height: 15px;
}
.radio-button-label {
	margin-top: 7px;
	margin-bottom: 20px;
	font-size: 18px;
}
.button {
	font-size: 18px;
	width: 250px;
	height: 50px;
	border: 1px solid lightskyblue;
	border-radius: 25px;
	background-color: lightskyblue;
	text-align: center;
	margin: 0 auto;
	box-shadow: 3px 3px 8px black;
}
.button:hover {
	transform: scale(1.05);
}
.report-button {
	text-align: center;
}
h2 {
	font-family: 'Lato';
	margin-left: 50px;
	padding-bottom: 5px;
}
.title {
	margin: 30px auto;
	color: lightskyblue;
	text-align: center;
	width: 50%;
	border-bottom: 0.5px dotted rgba(135, 206, 250, 0.3);
}
.toplevel-breakdown {
	margin-top: 0;
	padding: 0 7px;
	margin-bottom: 10px;
	border: 0.3px solid rgba(105, 206, 250, 0.4);
	box-shadow: 4px 2px 8px black;
}
.sublevel-breakdown {
	font-weight: 600;
	font-style: italic;
}
li {
	list-style: none;
	display: flex;
	justify-content: space-between;
	padding: 2px 0px;
	margin: 5px 0px;
}
.break-line {
	border-bottom: 1px dotted lightskyblue;
	margin-bottom: 35px;
}
.report-breakline {
	color: lightskyblue;
	border-top: 1px solid rgba(135, 206, 250, 0.3);
	text-align: center;
}
/* --------Signup Section------ */
.signup-title {
	font-size: 20px;
	text-align: center;
	margin-top: 50px;
	position: relative;
	font-weight: bold;
	color: lightskyblue;
}
.signup-color-box {
	margin-top: 250px;
	width: 100%;
	height: 350px;
	background: linear-gradient(rgba(135, 206, 250, 0.7), rgba(0, 0, 0, 0.7));
	position: absolute;
	z-index: -1;
}
.signup-box {
	margin: 0 auto;
	margin-top: 20px;
	background-color: white;
	width: 370px;
	height: 700px;
	border: 1px solid  lightskyblue;
	border-radius: 15px;
	box-shadow: 2px 2px 6px rgb(0, 0, 0);
}
.signup-div-wrap {
	width: 300px;
	margin: 0 auto;
	margin-top: 25px;
}

.signup-input-label {
	font-weight: bold;
	margin-bottom: 2px;
	margin-top: 20px;
	padding-left: 5px;
	padding-bottom: 5px;
}
.signup-error-message {
	width: 300px;
	height: 5px;
	text-align: left;
	padding-left: 2px;
	font-size: 12px;
	color: red;
}
.signup-input {
	width: 280px;
	height: 35px;
	margin: 10px auto;
	border: 0.5px solid lightskyblue;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
	border-radius: 8px;
	color: rgba(39, 39, 39, 0.7);
	padding-left: 15px;
}
.signup-button {
	text-align: center;
	margin-top: 15px;
}
.signup-button:hover {
	transform: scale(1.05);
}
.signup-button-label {
	border: none;
	text-align: center;
	margin: 10px auto;
	width: 200px;
	height: 40px;
	background-color:lightskyblue;
	color: white;
}
.signup-agreement {
	margin: 9px auto;
	max-width: 320px;
	font-size: 11px;
	text-align: center;
}

/* -------Sign In Section--------  */
.signin-box {
	position: relative;
	z-index: 10;
	width: 400px;
	height: 250px;
	background-color: rgba(0, 0, 0, 0.4);
	margin: 0 auto;
	border-radius: 10px;
	margin-top: -320px;
}
.form-wrapper {
	width: 400px;
	padding-top: 20px;
}
.username-box,
.password-box {
	display: block;
	width: 250px;
	height: 25px;
	margin: 15px auto;
}
.signin-button-wrapper {
	width: 350px;
	margin: 0 auto;
}
.signin-button {
	width: 150px;
	height: 35px;
	margin: 10px auto;
	background-color:lightskyblue;
	color: black;
	display: inherit;
}
.signin-button-label{
	margin: 20px 25px;
	color: lightskyblue;
	text-align: center;
}
.signin-message {
	color: white;
	text-align: center;
}
.not-signin-message {
	text-align: center;
	color: red;
}

/* --------Footer Section------ */

.footer-div {
	margin-top: 350px;
	width: 100%;
	height: 100px;
  border-top: 1px solid rgba(135, 206, 250, 0.3);
}
/* --------------Analysis Section--------- */
.analysis {
  width:500px;
  margin: 50px auto;
  display:block;
  text-align: center;
  color: lightskyblue;
}
.upload-icon {
  width:35px;
  height: auto;
}
.input-file {
  z-index: -1;
  display: none;
}
.header4 {
  font-size: 20px;
}
.input-file-label{
  margin:20px auto;
  height:50px;
  width: 300px;
  border:0.5px solid lightskyblue;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 2px 2px 3px black;
  background-color: black;
  opacity: 0.7;
}
.input-file-label:hover,.general-button:hover {
  opacity: 1;
}
.choose-file{
	padding-left: 10px;
}
.general-button {
	font-size: 15px;
	margin-top: 20px;
	margin-left:5px;
	width: 150px;
	height:35px;
	background-color: white;
	color: black;
	border:0.5px solid lightskyblue;
	border-radius: 5px;
	box-shadow: 2px 2px 3px black;
	opacity:0.8;
	cursor: pointer;
}
.general-button:hover {
  background-color: lightskyblue;
}
.general-button-style2 {
	font-size: 16px;
	margin-top:10px;
	width: 150px;
	height: 35px;
	border:0.5px solid lightskyblue;
	background-color: lightskyblue;
	margin-left:30px;
	border-radius: 8px;
}
.general-button-style2:hover {
background-color: white;
}
.add-button {
	font-size: 16px;
	width: 80px;
	height: 25px;
}
.add-button:hover{
	background-color: white;
}
.loading-block{
  width: 100%;
  height:80px
}
.loading {
  display: block;
  margin : 0 auto;
  border: 7px solid rgba(39,39,39);
  border-radius: 50%;
  border-bottom: 7px solid lightskyblue;
  border-top: 7px solid lightskyblue;
  border-right:7px solid lightskyblue;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 1s linear infinite;
}
.not-loading {
  display: none;
}
@webkit-keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.display-result {
  width:1350px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly;
}
.display-image {
  width: 1000px;
  margin: 30px auto;
  position: relative;
}
.result-image{
  width: 1000px;
  height: auto;
}
.display-cost-result {
	margin:0 20px
}
.general-table-row {
	min-width: 1000px;
	text-align: left;
}
td{min-width:250px;
}
td,th{
	padding-bottom: 5px;
}
.link-color2{
	color:lightskyblue;
	opacity: 0.8;
}
.link-color2:hover{
	opacity: 1;
}
/* general styling common*/
.general-header{
	color: lightskyblue;
	font-size: 20px;
	font-family: "Lato";
	margin-left: 10px;
	text-align: center;
}

/* ---------Responsive---------- */
@media (max-width: 1024px) {
  h2 {
    margin-left: 0px;
}
fieldset {
    padding-inline-start: 0rem;
    padding-inline-end: 0rem;
    padding-block-end: 0rem;
}
  .main-board {
    width: 96%;
}
.customer-board, .report-board, .inputs-board {
    display: block;
}
.report-breakline {
    margin: 10px 10px;
}
.project-input {
    padding-left: 10px;
}
.display-result {
  width:1000px;
  display:block;
}
}
@media (max-width: 768px) {

.caption-message-div {
	width: 700px;
}
fieldset {
    padding-inline-start: 0rem;
    padding-inline-end: 0rem;
    padding-block-end: 0rem;
}.wrapper {
    display: block;
}
.top-nav-list {
    font-size: 16px;
}

.display-result {
  width:700px;
}
.display-image {
  width: 700px;
}
.result-image{
  width: 700px;
}
}
@media (max-width: 414px) {
  .wrapper {
    display: block;
}
	nav {
		display: block;
		height: 300px;
	}
	.top-nav-list {
		display: block;
		padding: 2px 30px;
		font-size: 16px;
		text-align: center;
  }
  .title {
    width: 70%;
}
	.signup-box {
		width: 360px;
		height: 750px;
	}
	fieldset {
		box-shadow: 4px 4px 8px black;
		margin-inline-start: 0px;
		margin-inline-end: 0px;
		padding-block-start: 0;
		padding-inline-start: 0;
		padding-inline-end: 0;
		padding-block-end: 0;
	}
	.signin-box {
		margin-top: 20px;
		width: 340px;
	}
	.form-wrapper {
		width: 345px;
	}
	.caption-message-div, .intro-message-div {
	width: 350px;
}
.caption-message-div{
	margin-top: -220px;
	margin-bottom: -20px;
}
	.footer-div {
		margin-top: 250px;
	}
	.report-breakline {
		margin: 10px 10px;
	}
	.project-info-input1,
	.project-info-input2,
	.project-info-input3 {
		margin-left: 5px;
	}
	.project-info,
	.project-input {
		margin: 0px 5px;
		padding-left: 5px;
	}
	.customer-board,
	.report-board,
	.inputs-board {
		display: block;
		font-size: 14px;
	}
	.main-board2 {
		margin: 0 2%;
	}
	.project-report {
		margin: 0 10px;
		min-width: 330px;
	}
	h4 {
		padding-left: 10px;
	}
	.radio-button-label {
		margin-left: 5px;
  }
  .analysis {
    width:300px
  }
.display-result {
  width:300px;
}
.display-image {
  width: 300px;
}
.result-image{
  width: 300px;
}
}
@media (max-width : 320px) {
.top-nav {
    margin-right: 0px;
}
.signup-title {
    font-size: 16px;
}
.signup-box {
    width: 310px;
    height: 750px;
}
.signup-input {
    width: 270px;
    margin: 10px 5px;
}
.form-wrapper {
    width: 290px;
}
.signin-box {
    width: 290px;
}
.project-info-input1, .project-info-input2 {
    margin-left: 5px;
    width: 270px;
}
 .project-info-input3 {
    width: 280px;
}
.project-info, .project-input {
    width: 290px;
    padding-left: 3px;
}
.report-breakline {
    width: 280px;
}
.project-report {
    min-width: 285px;
}
.customer-board, .report-board, .inputs-board {
    font-size: 12px;
}
}